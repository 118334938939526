var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asterix-radio-group",
    { attrs: { column: "" } },
    [
      _vm._l(_vm.options, function (option) {
        return _c("asterix-radio-input", {
          key: `${option.id}`,
          attrs: {
            disabled: _vm.disabled,
            error: _vm.error,
            "input-value": option.id,
            name: _vm.name,
          },
          on: { change: _vm.changeOption },
          scopedSlots: _vm._u(
            [
              {
                key: "label",
                fn: function () {
                  return [_vm._v(" " + _vm._s(option.name) + " ")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.optionSelected,
            callback: function ($$v) {
              _vm.optionSelected = $$v
            },
            expression: "optionSelected",
          },
        })
      }),
      _vm._t("info"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }