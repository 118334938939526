import { api } from '.';
import { ADVERTISER_RESOURCE } from '@/services/keys';

export const getResources = id => ({
  [ADVERTISER_RESOURCE]: id,
});

/**
 *
 * @param {string} advertiserId
 * @returns {Promise.<string>}
 */
export async function deleteAdvertiser(advertiserId) {
  const partialUrl = api.createUrl(getResources(advertiserId));
  const {
    data: {
      advertiser: { id },
    },
  } = await api.delete(partialUrl);
  return id;
}
