export default class Tab {
  constructor(name = '', route = null, dirty = false, locked = false, preventNavigationIfDirty = false) {
    this.name = name;
    this.route = route;
    this.dirty = dirty;
    this.locked = locked;
    this.preventNavigationIfDirty = preventNavigationIfDirty;
    this.isFakeTab = false; // For fake tabs that behaves like a link
  }
}
