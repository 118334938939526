<template>
  <asterix-radio-group column>
    <asterix-radio-input
      v-for="option in options"
      :key="`${option.id}`"
      v-model="optionSelected"
      :disabled="disabled"
      :error="error"
      :input-value="option.id"
      :name="name"
      @change="changeOption"
    >
      <template #label> {{ option.name }} </template>
    </asterix-radio-input>
    <slot name="info"></slot>
  </asterix-radio-group>
</template>

<script>
import AsterixRadioInput from '@/components/atoms/AsterixRadioInput';
import AsterixRadioGroup from '@/components/molecules/shared/AsterixRadioGroup';

export default {
  name: 'AsterixRadioSelector',
  components: {
    AsterixRadioInput,
    AsterixRadioGroup,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    optionSelected: null,
    error: false,
  }),
  watch: {
    value(newVal) {
      this.optionSelected = newVal;
    },
  },
  created() {
    this.optionSelected = this.value;
  },
  methods: {
    changeOption() {
      this.error = false;
      this.$emit('input', this.optionSelected);
    },
  },
};
</script>
