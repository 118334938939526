import Tab from '@/model/shared/Tab';
import SelectOption from '@/model/shared/SelectOption';
import basicInfo from '@/router/private/modules/socialAudience/demand/campaign/createForm/basicInfo';
import basicInfoEdit from '@/router/private/modules/socialAudience/demand/campaign/editForm/basicInfo';
import targeting from '@/router/private/modules/socialAudience/demand/campaign/editForm/targeting';
import { creative } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative';
import {
  CAMPAIGN_STATUS,
  DRAFT_STATUS,
  PENDING_STATUS,
  PAUSED_STATUS,
  RUNNING_STATUS,
  COMPLETED_STATUS,
} from '@/model/modules/socialAudience/campaign/CampaignStatus';
import { CAMPAIGN_TYPES } from '@/model/modules/socialAudience/campaign/CampaignTypes';

export default {
  statusList: CAMPAIGN_STATUS,
  limitList: [new SelectOption('DAILY'), new SelectOption('TOTAL')],
  categoryList: CAMPAIGN_TYPES,
  tabs: {
    create: [
      new Tab('Details', basicInfo),
      new Tab('Creatives', creative, false, true),
      new Tab('Targeting', targeting, false, true),
    ],
    edit: [new Tab('Details', basicInfoEdit), new Tab('Creatives', creative), new Tab('Targeting', targeting)],
  },
  flowActions: {
    active: {
      id: 'active',
      toastText: 'actived',
      statusTransiction: {
        [DRAFT_STATUS.id]: PENDING_STATUS,
        [PENDING_STATUS.id]: RUNNING_STATUS,
        [PAUSED_STATUS.id]: RUNNING_STATUS,
      },
    },
    pause: {
      id: 'pause',
      toastText: 'paused',
      statusTransiction: {
        [RUNNING_STATUS.id]: PAUSED_STATUS,
      },
    },
    finish: {
      id: 'finish',
      toastText: 'finished',
      statusTransiction: {
        [PAUSED_STATUS.id]: COMPLETED_STATUS,
        [RUNNING_STATUS.id]: COMPLETED_STATUS,
      },
    },
  },
};
