import { api } from '.';
import { ADVERTISER_RESOURCE, CLIENT_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

const formatFilter = function (params, filterKey) {
  const totalCost = params.removeFilter(filterKey);
  if (totalCost) {
    const parse = /^\[(.+)\](.+)$/.exec(totalCost);
    if (parse) {
      const [, id, value] = parse;
      params.addFilter(`${filterKey}][${id}`, value);
    }
  }
};

export const getResources = clientId => ({
  [CLIENT_RESOURCE]: clientId,
  [ADVERTISER_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @param {string} clientId
 * @returns {Promise.<{data:Advertiser[],meta:EndpointMeta}>}
 */
export async function getAdvertisers(params = new QueryParamsBuilder(), clientId) {
  const partialUrl = api.createUrl(getResources(clientId));
  params.addInclude('category', 'subCategory');
  formatFilter(params, 'totalCost');
  formatFilter(params, 'last30DaysCost');

  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
