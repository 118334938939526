import SelectOption from '@/model/shared/SelectOption';

class CustomOption extends SelectOption {
  /**
   * @constructor
   * @type SelectOption
   * @property @param {string} id
   * @property @param {string|undefined} inputLabel
   */
  constructor(id, inputLabel = '', name = '') {
    super(id, name);
    this.inputLabel = inputLabel;
  }
}

const budgetOptions = {
  BUDGET: new SelectOption('BUDGET'),
  IMPRESSIONS: new SelectOption('IMPRESSIONS'),
  CLICKS: new SelectOption('CLICKS'),
  VIEWS: new CustomOption('VIEWS', 'CPM', 'View 100% Ad completion'),
};

const strategyOptions = {
  IMPRESSIONS: new CustomOption('IMPRESSIONS', 'CPM'),
  CLICKS: new CustomOption('CLICKS', 'CPC'),
  VIEWS: new CustomOption('VIEWS', 'CPM', 'View 100% Ad completion'),
};

export default {
  budgetSettingsValuesNative: [budgetOptions.BUDGET, budgetOptions.IMPRESSIONS, budgetOptions.CLICKS],
  budgetSettingsValuesVideo: [budgetOptions.BUDGET, budgetOptions.IMPRESSIONS, budgetOptions.VIEWS],
  strategyValueOptionsNative: [strategyOptions.IMPRESSIONS, strategyOptions.CLICKS],
  strategyValueOptionsVideo: [strategyOptions.IMPRESSIONS, strategyOptions.VIEWS],
  patternName: '^[^/:*?"<>|\\\\]*$',
  patternErrorMessage: 'The field contains invalid special characters: /, :, *, ?, ", <, >, |, \\',
  patternNumber: '^[0-9]*$',
  patternNumberErrorMessage: 'Only integer numbers',
  fieldsToObserve: [
    'campaign.name',
    'campaign.type.id',
    'campaign.startDate',
    'campaign.endDate',
    'campaign.advertiser.id',
    'campaign.budgetType',
    'campaign.budgetGoalType.id',
    'campaign.budgetGoal',
    'campaign.strategy',
    'campaign.strategyPrice',
  ],
};
