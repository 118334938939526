export const editingFormMixin = {
  data: () => ({
    initialObject: null,
    fieldsToObserve: [],
    editingObject: {},
    editing: false,
  }),
  created() {
    this.fieldsToObserve.forEach(property => {
      this.editingObject[property] = false;
      this.createObserver(property);
    });
  },
  methods: {
    createObserver(property) {
      this.$watch(property, this.getWatchForProperty(property));
    },
    getWatchForProperty(property) {
      return (newVal, oldVal, toObserve = property) => {
        this.checkProperty(toObserve);
      };
    },
    checkProperty(toObserve) {
      const [nameObject, firstProperty, secondProperty] = toObserve.split('.');
      let value = null;
      let valueInitial = null;

      if (nameObject) {
        value = this[nameObject];
        valueInitial = this.initialObject;
      }

      if (firstProperty) {
        value = this[nameObject][firstProperty];
        valueInitial = this.initialObject?.[firstProperty];
      }

      if (secondProperty) {
        value = this[nameObject]?.[firstProperty]?.[secondProperty];
        valueInitial = this.initialObject?.[firstProperty]?.[secondProperty];
      }
      const isEditingProperty = value !== valueInitial;
      this.editingObject[toObserve] = isEditingProperty;

      if (isEditingProperty) this.editing = true;
      else this.checkEditing();
    },
    checkEditing() {
      const valuesEditing = Object.values(this.editingObject);
      this.editing = valuesEditing.includes(true);
    },
  },
};
