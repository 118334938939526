import { api } from '.';
import { ADVERTISER_RESOURCE } from '@/services/keys';

export const getResources = id => ({
  [ADVERTISER_RESOURCE]: id,
});

/**
 *
 * @param {Advertiser} advertiser
 * @returns {Promise.<object>}
 */
export function updateAdvertiser(advertiser) {
  const partialUrl = api.createUrl(getResources(advertiser.id));
  return api.update(partialUrl, advertiser.payload());
}
