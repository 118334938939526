var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full text-sm" },
    [
      _c(
        "sun-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c("card-form", {
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [_vm._v("Basic Info")]
                },
                proxy: true,
              },
              {
                key: "form",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap items-center justify-end w-full text-gray-700 xl:w-auto",
                      },
                      [
                        _c("form-row", {
                          scopedSlots: _vm._u([
                            {
                              key: "left",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Name" } },
                                    [
                                      _c("asterix-input", {
                                        attrs: {
                                          disabled: _vm.readOnly,
                                          "auto-validate": "",
                                          maxlength: 50,
                                          minlength: 5,
                                          name: "name",
                                          pattern: _vm.patternName,
                                          "pattern-error-message":
                                            _vm.patternErrorMessage,
                                          placeholder: "A name...",
                                          required: "required",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.campaign.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.campaign, "name", $$v)
                                          },
                                          expression: "campaign.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "right",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Type" } },
                                    [
                                      _c("sun-select", {
                                        attrs: {
                                          disabled:
                                            _vm.readOnly ||
                                            _vm.campaignHasStarted,
                                          label: "name",
                                          name: "type",
                                          options: _vm.categoryList,
                                          "text-error":
                                            "This field is required",
                                          error: _vm.formErrors.type,
                                          required: "required",
                                          "close-on-select": "",
                                          "track-by": "id",
                                          "add-hex-color": "orange",
                                        },
                                        on: { change: _vm.changeType },
                                        model: {
                                          value: _vm.campaign.type,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.campaign, "type", $$v)
                                          },
                                          expression: "campaign.type",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c("form-row", {
                          scopedSlots: _vm._u([
                            {
                              key: "left",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Start Date" } },
                                    [
                                      _c("sun-date-picker", {
                                        staticClass: "w-full",
                                        attrs: {
                                          name: "startDate",
                                          "min-date": _vm.today,
                                          "max-date": _vm.maxDate,
                                          disabled:
                                            _vm.startDate.startDate <
                                            _vm.yesterday,
                                          "text-error":
                                            "This field is required",
                                          "cancel-error": "",
                                          value: _vm.startDate,
                                          required: "required",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeStartDate(
                                              $event.value.startDate
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "right",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "End Date" } },
                                    [
                                      _c("sun-date-picker", {
                                        staticClass: "w-full",
                                        attrs: {
                                          "min-date": _vm.minDate,
                                          name: "endDate",
                                          disabled: _vm.readOnly,
                                          value: _vm.endDate,
                                          "text-error":
                                            "This field is required",
                                          error: _vm.formErrors.endDate,
                                          required: "required",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeEndDate(
                                              $event.value.startDate
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _vm.isAdminOrAdop
                          ? _c("form-row", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "left",
                                    fn: function () {
                                      return [
                                        _c(
                                          "sun-label-group",
                                          { attrs: { text: "Advertiser" } },
                                          [
                                            _c("asterix-async-select", {
                                              staticClass: "v-full",
                                              attrs: {
                                                value: _vm.advertiserValue,
                                                disabled:
                                                  _vm.isAdvertiserDisabled,
                                                "add-hex-color": "orange",
                                                required: "required",
                                                "text-error":
                                                  "This field is required",
                                                error:
                                                  _vm.formErrors.advertiser,
                                                name: "advertiser",
                                                service: _vm.getAdvertisersList,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.setAdvertiser(
                                                    $event.items
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3928741531
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("card-form", {
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [_vm._v("Volumen & Price")]
                },
                proxy: true,
              },
              {
                key: "form",
                fn: function () {
                  return [
                    _c("form-row", {
                      scopedSlots: _vm._u([
                        {
                          key: "left",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-col border rounded-lg border-gray-400 p-5",
                                  class: {
                                    "rounded border border-red-400 bg-red-200":
                                      _vm.formErrors.budgetType,
                                  },
                                },
                                [
                                  _c(
                                    "sun-label-group",
                                    {
                                      attrs: {
                                        "is-required": "",
                                        text: "Budget Settings (Set daily or total limit)",
                                      },
                                    },
                                    [
                                      _c("form-row", {
                                        staticClass: "mt-2",
                                        scopedSlots: _vm._u([
                                          {
                                            key: "left",
                                            fn: function () {
                                              return [
                                                _c("asterix-radio-selector", {
                                                  staticClass:
                                                    "radio-group-min-width",
                                                  attrs: {
                                                    disabled: _vm.readOnly,
                                                    name: "budgetType",
                                                    options:
                                                      _vm.budgetSettingsValues,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.campaign.budgetType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "budgetType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "campaign.budgetType",
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "right",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("sun-select", {
                                                      staticClass: "mb-4",
                                                      attrs: {
                                                        label: "name",
                                                        name: "budgetGoalType",
                                                        disabled: _vm.readOnly,
                                                        options: _vm.limitList,
                                                        required: "required",
                                                        "text-error":
                                                          "This field is required",
                                                        "close-on-select": "",
                                                        "track-by": "id",
                                                        "add-hex-color":
                                                          "orange",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.campaign
                                                            .budgetGoalType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.campaign,
                                                            "budgetGoalType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "campaign.budgetGoalType",
                                                      },
                                                    }),
                                                    _c("asterix-input", {
                                                      attrs: {
                                                        "validate-on-blur": "",
                                                        disabled: _vm.readOnly,
                                                        name: "budgetGoal",
                                                        min: 0,
                                                        pattern:
                                                          _vm.patternNumber,
                                                        "pattern-error-message":
                                                          _vm.patternNumberErrorMessage,
                                                        placeholder: "0",
                                                        required: "required",
                                                        "text-error":
                                                          "This field is required",
                                                        type: "number",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.campaign
                                                            .budgetGoal,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.campaign,
                                                            "budgetGoal",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "campaign.budgetGoal",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "right",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border rounded-lg border-gray-400 p-5 md:items-center pb-0 md:pb-5 h-full",
                                  class: {
                                    "rounded border border-red-400 bg-red-200 pb-0":
                                      _vm.formErrors.strategy,
                                  },
                                },
                                [
                                  _c(
                                    "sun-label-group",
                                    {
                                      attrs: {
                                        "is-required": "",
                                        text: `Bid Strategy. (${_vm.maxPriceLabel})`,
                                      },
                                    },
                                    [
                                      _c("form-row", {
                                        staticClass: "mt-2",
                                        scopedSlots: _vm._u([
                                          {
                                            key: "left",
                                            fn: function () {
                                              return [
                                                _c("asterix-radio-selector", {
                                                  staticClass:
                                                    "radio-group-min-width",
                                                  attrs: {
                                                    disabled: _vm.readOnly,
                                                    name: "bidStrategy",
                                                    options:
                                                      _vm.strategyValueOptions,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      _vm.isCampaignVideo
                                                        ? {
                                                            key: "info",
                                                            fn: function () {
                                                              return [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "italic text-xs sm:max-w-md lg:mx-4",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Videos shorter than 30 seconds"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                            proxy: true,
                                                          }
                                                        : null,
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.campaign.strategy,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.campaign,
                                                        "strategy",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "campaign.strategy",
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                          {
                                            key: "right",
                                            fn: function () {
                                              return [
                                                _c("currency-selector", {
                                                  staticClass:
                                                    "currency-selector-height",
                                                  attrs: {
                                                    value: _vm.strategyPrice,
                                                    "currency-disabled": "",
                                                    "default-currency": "EUR",
                                                    disabled: _vm.readOnly,
                                                    name: "strategyPrice",
                                                    placeholder: "0.00",
                                                    required: "required",
                                                    "text-error":
                                                      "Required field",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      _vm.campaign.strategyPrice =
                                                        $event.value
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "div",
            {
              staticClass:
                "mb-4 mt-12 form-row flex flex-col lg:flex-row w-full",
            },
            [
              _c("save-button", {
                ref: "submitBtn",
                staticClass: "mx-1",
                attrs: { disabled: _vm.readOnly, loading: _vm.isLoading },
              }),
              !_vm.campaign.id
                ? _c(
                    "sun-button",
                    {
                      staticClass:
                        "mx-1 bg-gray-700 text-white rounded shadow-md hover:bg-gray-900 custom-p-1",
                      attrs: {
                        disabled: _vm.isLoading || _vm.readOnly,
                        color: "gray",
                        variant: "pill",
                      },
                      on: { click: _vm.saveAndCreateInsertion },
                    },
                    [_vm._v(" Save and go next step ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }