<template>
  <div class="w-full text-sm">
    <sun-form @submit="onSubmit">
      <card-form>
        <template #title>Basic Info</template>
        <template #form>
          <div class="flex flex-wrap items-center justify-end w-full text-gray-700 xl:w-auto">
            <form-row>
              <template #left>
                <sun-label-group text="Name">
                  <asterix-input
                    v-model="campaign.name"
                    :disabled="readOnly"
                    auto-validate
                    :maxlength="50"
                    :minlength="5"
                    name="name"
                    :pattern="patternName"
                    :pattern-error-message="patternErrorMessage"
                    placeholder="A name..."
                    required="required"
                    type="text"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="Type">
                  <sun-select
                    v-model="campaign.type"
                    :disabled="readOnly || campaignHasStarted"
                    label="name"
                    name="type"
                    :options="categoryList"
                    text-error="This field is required"
                    :error="formErrors.type"
                    required="required"
                    close-on-select
                    track-by="id"
                    add-hex-color="orange"
                    @change="changeType"
                  />
                </sun-label-group>
              </template>
            </form-row>
            <form-row>
              <template #left>
                <sun-label-group text="Start Date">
                  <sun-date-picker
                    name="startDate"
                    :min-date="today"
                    :max-date="maxDate"
                    :disabled="startDate.startDate < yesterday"
                    class="w-full"
                    text-error="This field is required"
                    cancel-error
                    :value="startDate"
                    required="required"
                    @change="changeStartDate($event.value.startDate)"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="End Date">
                  <sun-date-picker
                    :min-date="minDate"
                    name="endDate"
                    class="w-full"
                    :disabled="readOnly"
                    :value="endDate"
                    text-error="This field is required"
                    :error="formErrors.endDate"
                    required="required"
                    @change="changeEndDate($event.value.startDate)"
                  />
                </sun-label-group>
              </template>
            </form-row>
            <form-row v-if="isAdminOrAdop">
              <template #left>
                <sun-label-group text="Advertiser">
                  <asterix-async-select
                    :value="advertiserValue"
                    class="v-full"
                    :disabled="isAdvertiserDisabled"
                    add-hex-color="orange"
                    required="required"
                    text-error="This field is required"
                    :error="formErrors.advertiser"
                    name="advertiser"
                    :service="getAdvertisersList"
                    @change="setAdvertiser($event.items)"
                  />
                </sun-label-group>
              </template>
            </form-row>
          </div>
        </template>
      </card-form>
      <card-form>
        <template #title>Volumen & Price</template>
        <template #form>
          <form-row>
            <template #left>
              <div
                class="flex flex-col border rounded-lg border-gray-400 p-5"
                :class="{
                  'rounded border border-red-400 bg-red-200': formErrors.budgetType,
                }"
              >
                <sun-label-group is-required text="Budget Settings (Set daily or total limit)">
                  <form-row class="mt-2">
                    <template #left>
                      <asterix-radio-selector
                        v-model="campaign.budgetType"
                        class="radio-group-min-width"
                        :disabled="readOnly"
                        name="budgetType"
                        :options="budgetSettingsValues"
                      >
                      </asterix-radio-selector>
                    </template>
                    <template #right>
                      <div>
                        <sun-select
                          v-model="campaign.budgetGoalType"
                          class="mb-4"
                          label="name"
                          name="budgetGoalType"
                          :disabled="readOnly"
                          :options="limitList"
                          required="required"
                          text-error="This field is required"
                          close-on-select
                          track-by="id"
                          add-hex-color="orange"
                        />
                        <asterix-input
                          v-model="campaign.budgetGoal"
                          validate-on-blur
                          :disabled="readOnly"
                          name="budgetGoal"
                          :min="0"
                          :pattern="patternNumber"
                          :pattern-error-message="patternNumberErrorMessage"
                          placeholder="0"
                          required="required"
                          text-error="This field is required"
                          type="number"
                        />
                      </div>
                    </template>
                  </form-row>
                </sun-label-group>
              </div>
            </template>
            <template #right>
              <div
                class="border rounded-lg border-gray-400 p-5 md:items-center pb-0 md:pb-5 h-full"
                :class="{
                  'rounded border border-red-400 bg-red-200 pb-0': formErrors.strategy,
                }"
              >
                <sun-label-group is-required :text="`Bid Strategy. (${maxPriceLabel})`">
                  <form-row class="mt-2">
                    <template #left>
                      <asterix-radio-selector
                        v-model="campaign.strategy"
                        class="radio-group-min-width"
                        :disabled="readOnly"
                        name="bidStrategy"
                        :options="strategyValueOptions"
                      >
                        <template v-if="isCampaignVideo" #info>
                          <span class="italic text-xs sm:max-w-md lg:mx-4">Videos shorter than 30 seconds</span>
                        </template>
                      </asterix-radio-selector>
                    </template>
                    <template #right>
                      <currency-selector
                        :value="strategyPrice"
                        class="currency-selector-height"
                        currency-disabled
                        default-currency="EUR"
                        :disabled="readOnly"
                        name="strategyPrice"
                        placeholder="0.00"
                        required="required"
                        text-error="Required field"
                        @input="campaign.strategyPrice = $event.value"
                      />
                    </template>
                  </form-row>
                </sun-label-group>
              </div>
            </template>
          </form-row>
        </template>
      </card-form>
      <div class="mb-4 mt-12 form-row flex flex-col lg:flex-row w-full">
        <save-button ref="submitBtn" class="mx-1" :disabled="readOnly" :loading="isLoading"></save-button>
        <sun-button
          v-if="!campaign.id"
          :disabled="isLoading || readOnly"
          class="mx-1 bg-gray-700 text-white rounded shadow-md hover:bg-gray-900 custom-p-1"
          color="gray"
          variant="pill"
          @click="saveAndCreateInsertion"
        >
          Save and go next step
        </sun-button>
      </div>
    </sun-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import metaInfo from '@/mixins/common/metaInfo';
import { editingFormMixin } from '@/mixins/form/editingFormMixin';
import CardForm from '@/components/atoms/CardForm';
import CurrencySelector from '@/components/atoms/CurrencySelector/CurrencySelector';
import FormRow from '@/components/atoms/FormRow/FormRow.vue';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import { createCampaign, updateCampaign } from '@/services/modules/socialAudience/campaign/';
import SaveButton from '@/components/atoms/SaveButton';
import AsterixInput from '@/components/atoms/AsterixInput';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import Campaign from '@/entities/socialAudience/Campaign';
import CONFIG from '@/components/organisms/modules/socialAudience/campaign/form/config';
import BasicInfoConfig from './config';
import { getAdvertisers, getAdvertiserById } from '@/services/modules/socialAudience/advertiser';
import { TODAY } from '@/utils/dateTime/today';
import { YESTERDAY } from '@/utils/dateTime/yesterday';
import { addDays } from '@/utils/dateTime/addDays';
import { deepClone } from '@/utils/deepClone';
import AsterixRadioSelector from '@/components/atoms/AsterixRadioSelector';
import { VIDEO_TYPE } from '@/model/modules/socialAudience/campaign/CampaignTypes';
import { DRAFT_STATUS } from '@/model/modules/socialAudience/campaign/CampaignStatus';
import { CONTEXTS } from '@/model/shared/contexts';
import { USER } from '@/store/modules/auth/keys';
import { ROLES } from '@/model/shared/roles';

export default {
  name: 'BasicInfo',
  components: {
    CardForm,
    FormRow,
    SaveButton,
    AsterixInput,
    CurrencySelector,
    AsterixAsyncSelect,
    AsterixRadioSelector,
  },
  mixins: [metaInfo, editingFormMixin],
  props: {
    campaign: {
      type: Campaign,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLoading: false,
    statusList: CONFIG.statusList,
    limitList: CONFIG.limitList,
    categoryList: CONFIG.categoryList,
    formErrors: {
      advertiser: false,
      budgetType: false,
      endDate: false,
      strategy: false,
      type: false,
    },
    advertiserSelected: null,
    strategyPrice: null,
    minDate: new Date(),
    maxDate: null,
    yesterday: YESTERDAY,
    today: TODAY,
    nextStep: false,
    patternName: BasicInfoConfig.patternName,
    patternErrorMessage: BasicInfoConfig.patternErrorMessage,
    patternNumber: BasicInfoConfig.patternNumber,
    patternNumberErrorMessage: BasicInfoConfig.patternNumberErrorMessage,
    fieldsToObserve: BasicInfoConfig.fieldsToObserve,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      user: USER,
    }),
    maxPriceLabel() {
      const type = this.strategyValueOptions.find(option => option.id === this.campaign.strategy);
      return `Max Price ${type?.inputLabel || ''}`;
    },
    advertiserValue() {
      return this.initialObject.advertiser?.id ? [this.initialObject.advertiser] : [];
    },
    startDate() {
      return {
        startDate: this.campaign.startDate,
        endDate: this.campaign.startDate,
      };
    },
    endDate() {
      return {
        startDate: this.campaign.endDate,
        endDate: this.campaign.endDate,
      };
    },
    isCampaignVideo() {
      return VIDEO_TYPE.isEqual(this.campaign.type);
    },
    strategyValueOptions() {
      if (this.isCampaignVideo) return BasicInfoConfig.strategyValueOptionsVideo;
      return BasicInfoConfig.strategyValueOptionsNative;
    },
    budgetSettingsValues() {
      if (this.isCampaignVideo) return BasicInfoConfig.budgetSettingsValuesVideo;
      return BasicInfoConfig.budgetSettingsValuesNative;
    },
    advertiserIdParam() {
      return this.$route.query.advertiserId;
    },
    existsDefaultAdvertiser() {
      return this.advertiserIdParam && !!this.initialObject.advertiser.id;
    },
    isAdvertiserDisabled() {
      return this.readOnly || this.isLoading || this.existsDefaultAdvertiser;
    },
    campaignHasStarted() {
      return this.campaign.status.id !== DRAFT_STATUS.id;
    },
    contextUserRole() {
      return this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.SOCIAL_AUDIENCE.id).role;
    },
    isAdminOrAdop() {
      return this.contextUserRole === ROLES.ADMIN.id || this.contextUserRole === ROLES.AD_OPS.id;
    },
  },
  watch: {
    editing(value) {
      this.emitEdit(value);
    },
  },
  async created() {
    this.initialObject = deepClone(this.campaign);
    if (this.advertiserIdParam) await this.getDefaultAdvertiser();
    this.budgetGoalType = this.campaign.budgetGoalType;
    this.strategyPrice = this.campaign.strategyPrice ? { value: this.campaign.strategyPrice, currency: 'EUR' } : null;
    this.setMaxDate(this.campaign.endDate);
    this.setMinDate(this.campaign.startDate);
    this.advertiserSelected = this.campaign.advertiser;
    this.emitEdit(false);
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    metaTitleReplacement() {
      return { campaign: this.campaign.name };
    },
    getAdvertisersList(params) {
      return getAdvertisers(params, this.activeClient.id);
    },
    async onSubmit({ form, valid }) {
      const updateOrCreateText = this.campaign.id ? 'updated' : 'created';
      try {
        this.isLoading = true;
        this.formErrors.type = !this.campaign.type;
        this.formErrors.advertiser = !this.advertiserSelected?.id;
        this.formErrors.budgetType = !this.campaign.budgetType;
        this.formErrors.strategy = !this.campaign.strategy;
        this.formErrors.endDate = !this.endDate;

        if (this.formErrors.advertiser || this.formErrors.budgetType || this.formErrors.strategy || !valid) return;
        const campaign = new Campaign(
          this.campaign.id,
          form.name.value,
          this.advertiserSelected,
          form.startDate.value.startDate,
          form.endDate.value.startDate,
          null, // status
          null, // isSync
          form.type.value,
          this.campaign.budgetType,
          form.budgetGoalType.value,
          form.budgetGoal.value,
          this.campaign.strategy,
          this.campaign.strategyPrice
        );
        if (campaign.id) {
          await updateCampaign(campaign);
        } else {
          const { data } = await createCampaign(campaign);
          campaign.id = data;
        }
        const toast = Toast.success(
          `Campaign ${updateOrCreateText}`,
          `Campaign was ${updateOrCreateText} successfully`
        );
        this.createToast(toast);

        this.$emit('update', { entity: campaign, nextStep: this.nextStep });
      } catch (e) {
        this.createToast(Toast.error(`Campaign not ${updateOrCreateText}`, e.message));
      } finally {
        this.isLoading = false;
      }
    },
    async saveAndCreateInsertion() {
      this.nextStep = true;
      this.$refs.submitBtn.$el.click();
    },
    setMaxDate(date) {
      this.maxDate = date ? addDays(date, -1) : this.maxDate;
    },
    setMinDate(date) {
      // TODO:deleted this.minDate = date ? (date < TODAY ? TODAY : date) : TODAY;
      this.minDate = date && date > TODAY ? date : TODAY;
    },
    changeStartDate(date) {
      this.setMinDate(date);
      this.campaign.startDate = date;
    },
    changeEndDate(date) {
      this.setMaxDate(date);
      this.campaign.endDate = date;
    },
    emitEdit(value) {
      this.$emit('edit-form', value);
    },
    setAdvertiser(value) {
      this.formErrors.advertiser = null;
      this.advertiserSelected = value;
      this.campaign.advertiser = value;
    },
    changeType() {
      this.campaign.typeIsChanged();
      this.formErrors.type = false;
    },
    async getDefaultAdvertiser() {
      this.isLoading = true;
      try {
        const { data } = await getAdvertiserById(this.advertiserIdParam);
        this.initialObject.advertiser = data;
        this.campaign.advertiser = data;
      } catch (e) {
        this.createToast(Toast.error('Can not get advertiser', e.message));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.currency-selector-height {
  height: 74px;
}
.input-limit-value {
  height: 42px;
}
.radio-group-min-width {
  min-width: 173px;
}
</style>
