import { api } from '.';
import { ADVERTISER_RESOURCE } from '@/services/keys';

/**
 *
 * @param {Advertiser} advertiser
 * @returns {Promise.<object>}
 */
export function createAdvertiser(advertiser) {
  const partialUrl = api.createUrl({
    [ADVERTISER_RESOURCE]: undefined,
  });
  return api.create(partialUrl, advertiser.payload());
}
