import { getAdvertisers } from './getAdvertisers';
import { getAdvertiserById } from './getAdvertiserById';
import { createAdvertiser } from './createAdvertiser';
import { updateAdvertiser } from './updateAdvertiser';
import { deleteAdvertiser } from './deleteAdvertiser';

import { socialAudience } from '..';

export const api = socialAudience;

export { getAdvertisers, getAdvertiserById, createAdvertiser, updateAdvertiser, deleteAdvertiser };
